
import React, {useState, useEffect} from "react"

import PackagescheduleeditorPage from "../../components/negosyonow/controls/packagescheduleeditor";


const searchFields = [
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"Seller", "dbfield": "onsbranch_name", "type": "text", "filtertype": "textbox"},
	{"label":"Fulfillment Method", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "textbox"},
	{"label":"Location/Provider", "dbfield": "nnpickup_name", "type": "text", "filtertype": "textbox"},
	{"label":"Delivery Address", "dbfield": "onsaddress_name", "type": "text", "filtertype": "textbox"},
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"Order Status", "dbfield": "onsbookingstatus_name", "type": "text", "filtertype": "textbox"},
	{"label":"Drop off Location", "dbfield": "nndropoff_name", "type": "text", "filtertype": "textbox"},
	{"label":"Drop off Date", "dbfield": "onsbooking_targetdropoffdate", "type": "datetime", "filtertype": "datetime"},
	{"label":"Delivery/Availability Date", "dbfield": "onsbooking_targetdeliverydate", "type": "datetime", "filtertype": "datetime"},
	{"label":"Last Updated", "dbfield": "onsbooking_updated", "type": "datetime", "filtertype": "datetime"},
];

const PackagepickupschedulePage = ({location}) => {


	return <PackagescheduleeditorPage
			location={location}
			searchFields={searchFields}
			userSearchParam={" and ONSADDRESS.onsaddress_id=ONSBOOKING.onsaddress_id"}
		/>

}


export default PackagepickupschedulePage;
