
import React, {useState} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"


const formatTools = require("../../../../lib/formatTools");
const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="onsbooking"

const formFields = [
	[
		{"label":"Order Date", "field": "onsbooking_datetime", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Order #", "field": "onsorderform_code", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Seller", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Customer", "field": "onscustomer_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Last Updated", "field": "onsbooking_updated", "value": "", "input": "datetime", "mode": "readonly"},

		{"label":"Fulfillment Method", "field": "onsorderformfulfillment_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Location/Provider", "field": "nnpickup_name", "value":"", "input": "picker", "mode": "readonly"},

		{"label":"Delivery Address", "field": "onsaddress_name", "value": "", "input": "picker", "mode": "readonly"},


		{"label":"Drop off Location", "field": "nndropoff_name", "value":"", "input": "picker", "mode": "readonly"},
		{"label":"Drop off Date", "field": "onsbooking_targetdropoffdate", "value":"", "input": "date", "mode": "readonly"},

		{"label":"Delivery/Availability Date", "field": "onsbooking_targetdeliverydate", "value":"", "input": "date", "mode": "required"},

		{"label":"Order Status", "field": "onsbookingstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
			//{"value":1,"display":"Ordered"},
			{"value":4,"display":"Scheduled/Rescheduled"},
			{"value":9,"display":"For scheduling"},
			{"value":6,"display":"For re-scheduling"},
			//{"value":7,"display":"Accepted/For dispatching"},
			//{"value":8,"display":"Packed"},
			//{"value":2,"display":"Dispatched"},
			//{"value":3,"display":"Completed"},
			//{"value":5,"display":"Cancelled"}
		]},


		{"label":"Last Location", "field": "nnpickupalias_name", "value":"", "input": "picker", "mode": "readonly"},
		{"label":"Package Updated", "field": "onsbooking_locationdate", "value":"", "input": "datetime", "mode": "readonly"}

	]
];

const defaultSubformFields = [
	{
		"subformid": "packages",
		"label": "Packaging",
		"table": "onsbookingpackage",
		"sort": "onsbookingpackage_id",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Package #",
				"field": "onsbookingpackage_packagenum",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Description/Content",
				"field": "onsbookingpackage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			}
		],
		"footerlist": []
	},
	{
		"subformid": "onsbookinglocationhistory",
		"label": "History",
		"table": "onsbookinglocationhistory",
		"sort": "onsbookinglocationhistory_datetime desc",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Date/Time",
				"field": "onsbookinglocationhistory_datetime",
				"subtotalid": "",
				"value": "",
				"input": "datetime",
				"mode": "readonly"
			},
			{
				"label": "Location",
				"field": "nnpickup_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
			{
				"label": "Activity",
				"field": "onsbookinglocationhistory_action",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
			{
				"label": "Encoder",
				"field": "onsbookinglocationhistory_user",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
			{
				"label": "Notes",
				"field": "onsbookinglocationhistory_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			}
		],
		"footerlist": []
	},
	{
		"subformid": "item",
		"label": "Items",
		"table": "onsbookingitem",
		"sort": "onsbookingitem_id",
		"mobilerowtitlefieldidx":[0,4],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Item",
				"field": "onsproduct_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
			{
				"label": "Active",
				"field": "onsbookingitem_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "readonly"
			},
			{
				"label": "Product Active",
				"field": "onsproduct_active",
				"subtotalid": "",
				"value": "",
				"input": "hidden",
				"mode": "readonly"
			},
			{
				"label": "Available QTY",
				"field": "onsbookingitem_pickqty",
				"subtotalid": "availableorderpcs",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Packed QTY",
				"field": "onsbookingitem_deliverqty",
				"subtotalid": "deliverorderpcs",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Notes",
				"field": "onsbookingitem_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
		],
		"footerlist": [
			{
				"label": "Fees",
				"field": "onsbooking_fees",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "required"
			}
		]
	}
];


const PackagescheduleeditorPage = ({location, searchFields, userSearchParam}) => {
	const [token, setToken] = useState("");
	const [userinfo, setUserinfo] = useState({});
	const [pagetitle, setPagetitle] = useState("");

	const [onsorderformid, setOnsorderformid] = useState(0);
	const [onsorderformfulfillmentid, setoOsorderformfulfillmentid] = useState(0);
	const [bookingstatusid, setBookingstatusid] = useState(0);


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var onsbookingstatus_id=bookingstatusid;
		//var pkid = 0;
		var result = "Scheduled";
		//var customparam = {};
		const updatetimestr = formatTools.getDateStr();
		var customparam = JSON.parse(JSON.stringify(params));
		var tmpnotes = "";
/*
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
*/
		if (params.hasOwnProperty("onsbookingstatus_id")) {
			if (params.onsbookingstatus_id) {
				onsbookingstatus_id = params.onsbookingstatus_id;
			}
		}


		customparam.onsbooking_updated = updatetimestr;

		//callback({"status":"Error", "message":"Debug"}); return;

		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				handlePackageHistory(response.documentid, token, updatetimestr, 7, 6 /* New */, "Scheduled", function(historyres) {
					triggerNotifications(response.documentid, onsorderformid, result, token, function() {
						callback(response);
					});
				}); // handlePackageHistory
			} else {
				callback(response);
			}
		});

	}

	function triggerNotifications(pkid, orderformid, result, token, callback)
	{
		if (result.length < 1) {
			callback();
			return;
		}
		// Use better SNS, no need to recompute
		negosyonowAPI.buildOrder(token, "order",{
			"notifysubject": result,
			"orderform": orderformid,
			"onsbooking": pkid,
			"action": "recomputeorder"
		}).then(nnresponse => {
			callback();
		});
	}

	function handlePackageHistory(pkid, token, updatetimestr, nnpickup_id, packagestatus_id, notes, callback)
	{
		var action = "Others";

		webappsAPI.saveRecord("ONSBOOKINGLOCATIONHISTORY", {
				"onsbooking_id": pkid,
				"onsbookinglocationhistory_datetime": updatetimestr,
				"nnpickup_id": nnpickup_id,
				"onsbookinglocationhistory_action": action,
				"onsbookinglocationhistory_notes": notes,
				"onsbookinglocationhistory_user": userinfo.name
			}, token).then(historyresponse => {
				callback({"status":"OK", "message":"Recorded"});
			}); // add history
	}

	function customSetFormData(newformdata)
	{
		var newbookingstatusid = 0;
		var neworderformid = 0;
		var newfulfillmentid = 0;


		if (newformdata.hasOwnProperty("onsorderform_id")) {
			if (newformdata.onsorderform_id) {
				neworderformid = newformdata.onsorderform_id;
			}
		}
		if (newformdata.hasOwnProperty("onsbookingstatus_id")) {
			if (newformdata.onsbookingstatus_id) {
				newbookingstatusid = newformdata.onsbookingstatus_id;
			}
		}
		if (newformdata.hasOwnProperty("onsorderformfulfillment_id")) {
			if (newformdata.onsorderformfulfillment_id) {
				newfulfillmentid = newformdata.onsorderformfulfillment_id;
			}
		}

		setBookingstatusid(newbookingstatusid);
		setOnsorderformid(neworderformid);
		setoOsorderformfulfillmentid(newfulfillmentid);
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (inputlist[idx].field === "onsbookingstatus_id") {
/*
			{"value":1,"display":"Ordered"},
			{"value":4,"display":"Allocated/Scheduled"},
			{"value":9,"display":"For scheduling"},
			{"value":6,"display":"For re-scheduling"},
			{"value":7,"display":"Accepted/For dispatching"},
			{"value":8,"display":"Packed"},
			{"value":2,"display":"Dispatched"},
			{"value":3,"display":"Completed"},
			{"value":5,"display":"Cancelled"}
*/
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (bookingstatusid === 9) {
				tmpfield.options = [
					{"value":9,"display":"For scheduling"},
					{"value":4,"display":"Scheduled"}
				];
			} else if (bookingstatusid === 6) {
				tmpfield.options = [
					{"value":6,"display":"For re-scheduling"},
					{"value":4,"display":"Scheduled"}
				];
			} else if (bookingstatusid === 1) {
				// TODO: Remove this section when live
				tmpfield.options = [
					{"value":1,"display":"Ordered"},
					{"value":4,"display":"Scheduled"}
				];
			} else {
				tmpfield.mode = "readonly";
			}
			return tmpfield;
		} else if (inputlist[idx].field === "onsaddress_name") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";

			if (onsorderformfulfillmentid !== 2) {
				tmpfield.value = "N/A (Not for Delivery)";
			}
			return tmpfield;
		} else if (inputlist[idx].field === "onsbooking_fees") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (onsorderformfulfillmentid === 3 || bookingstatusid !== 9) {
				// Pickup, no longer for scheduling status
				tmpfield.mode = "readonly";
			}
			return tmpfield;

			//
		} else if (inputlist[idx].field === "onsbooking_targetdeliverydate") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (bookingstatusid !== 9) {
				// Pickup, no longer for scheduling status
				tmpfield.mode = "readonly";
			}
			return tmpfield;
		}

		return inputlist[idx];

	}


	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var newstatus = 0;
		var idx = 0;

		while (idx < mainform.length) {
			if (mainform[idx].field === "onsbookingstatus_id") {
				newstatus = parseInt(mainform[idx].value, 10);
			}
			idx++;
		}

		if (newstatus !== 4) {
			return {"status":"Error", "message":"Please update the status"};
		}
		return {"status":"OK"};
	}

	return <Layout fullPath={location.pathname}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={false}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["onsorderform_code","onsorderform_datetime"]}
				userSearchParam={{"orderby":"onsorderform_datetime desc", "joincond": "ONSORDERFORM.onsorderform_id=ONSBOOKING.onsorderform_id and ONSBRANCH.onsbranch_id=ONSBOOKING.onsbranch_id and ONSORDERFORMFULFILLMENT.onsorderformfulfillment_id=ONSBOOKING.onsorderformfulfillment_id and NNPICKUP.nnpickup_id=ONSBOOKING.nnpickup_id and NNDROPOFF.nndropoff_id=ONSBOOKING.nndropoff_id and ONSCUSTOMER.onscustomer_id=ONSBOOKING.onscustomer_id and ONSBOOKINGSTATUS.onsbookingstatus_id=ONSBOOKING.onsbookingstatus_id"+userSearchParam}}
				searchFields={searchFields}

				customSubmit={customSubmit}
				customSetFormData={customSetFormData}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={customValidateValues}
				editFields={formFields}
				editSubFormFields={defaultSubformFields}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default PackagescheduleeditorPage;
